/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { FxIdDomainSettingsUrlOpenMethod } from './FxIdDomainSettingsUrlOpenMethod';
import {
    FxIdDomainSettingsUrlOpenMethodFromJSON,
    FxIdDomainSettingsUrlOpenMethodFromJSONTyped,
    FxIdDomainSettingsUrlOpenMethodToJSON,
    FxIdDomainSettingsUrlOpenMethodToJSONTyped,
} from './FxIdDomainSettingsUrlOpenMethod';
import type { FxIdWebFeaturesPlayGameResponsePresentation } from './FxIdWebFeaturesPlayGameResponsePresentation';
import {
    FxIdWebFeaturesPlayGameResponsePresentationFromJSON,
    FxIdWebFeaturesPlayGameResponsePresentationFromJSONTyped,
    FxIdWebFeaturesPlayGameResponsePresentationToJSON,
    FxIdWebFeaturesPlayGameResponsePresentationToJSONTyped,
} from './FxIdWebFeaturesPlayGameResponsePresentation';
import type { FxIdWebFeaturesPlayGameResponseStatistics } from './FxIdWebFeaturesPlayGameResponseStatistics';
import {
    FxIdWebFeaturesPlayGameResponseStatisticsFromJSON,
    FxIdWebFeaturesPlayGameResponseStatisticsFromJSONTyped,
    FxIdWebFeaturesPlayGameResponseStatisticsToJSON,
    FxIdWebFeaturesPlayGameResponseStatisticsToJSONTyped,
} from './FxIdWebFeaturesPlayGameResponseStatistics';
import type { FxIdDomainSettingsEGameOrientation } from './FxIdDomainSettingsEGameOrientation';
import {
    FxIdDomainSettingsEGameOrientationFromJSON,
    FxIdDomainSettingsEGameOrientationFromJSONTyped,
    FxIdDomainSettingsEGameOrientationToJSON,
    FxIdDomainSettingsEGameOrientationToJSONTyped,
} from './FxIdDomainSettingsEGameOrientation';
import type { FxIdWebFeaturesPlayGameResponseSupport } from './FxIdWebFeaturesPlayGameResponseSupport';
import {
    FxIdWebFeaturesPlayGameResponseSupportFromJSON,
    FxIdWebFeaturesPlayGameResponseSupportFromJSONTyped,
    FxIdWebFeaturesPlayGameResponseSupportToJSON,
    FxIdWebFeaturesPlayGameResponseSupportToJSONTyped,
} from './FxIdWebFeaturesPlayGameResponseSupport';
import type { FxIdWebFeaturesPlayPlatformDataOptions } from './FxIdWebFeaturesPlayPlatformDataOptions';
import {
    FxIdWebFeaturesPlayPlatformDataOptionsFromJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPlatformDataOptionsToJSON,
    FxIdWebFeaturesPlayPlatformDataOptionsToJSONTyped,
} from './FxIdWebFeaturesPlayPlatformDataOptions';
import type { FxIdWebFeaturesPlayGameResponseFxLauncherOptions } from './FxIdWebFeaturesPlayGameResponseFxLauncherOptions';
import {
    FxIdWebFeaturesPlayGameResponseFxLauncherOptionsFromJSON,
    FxIdWebFeaturesPlayGameResponseFxLauncherOptionsFromJSONTyped,
    FxIdWebFeaturesPlayGameResponseFxLauncherOptionsToJSON,
    FxIdWebFeaturesPlayGameResponseFxLauncherOptionsToJSONTyped,
} from './FxIdWebFeaturesPlayGameResponseFxLauncherOptions';
import type { FxIdDomainSettingsPublicWebClientConfigOptions } from './FxIdDomainSettingsPublicWebClientConfigOptions';
import {
    FxIdDomainSettingsPublicWebClientConfigOptionsFromJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsFromJSONTyped,
    FxIdDomainSettingsPublicWebClientConfigOptionsToJSON,
    FxIdDomainSettingsPublicWebClientConfigOptionsToJSONTyped,
} from './FxIdDomainSettingsPublicWebClientConfigOptions';
import type { FxIdWebFeaturesPlayPublicDataBaseStoreOptions } from './FxIdWebFeaturesPlayPublicDataBaseStoreOptions';
import {
    FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSON,
    FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSONTyped,
    FxIdWebFeaturesPlayPublicDataBaseStoreOptionsToJSON,
    FxIdWebFeaturesPlayPublicDataBaseStoreOptionsToJSONTyped,
} from './FxIdWebFeaturesPlayPublicDataBaseStoreOptions';

/**
 * 
 * @export
 * @interface FxIdWebFeaturesPlayPublicDataBase
 */
export interface FxIdWebFeaturesPlayPublicDataBase {
    /**
     * 
     * @type {FxIdWebFeaturesPlayPlatformDataOptions}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    PlatformData: FxIdWebFeaturesPlayPlatformDataOptions;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponseStatistics}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    Statistics?: FxIdWebFeaturesPlayGameResponseStatistics | null;
    /**
     * 
     * @type {FxIdDomainSettingsEGameOrientation}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    GameOrientation: FxIdDomainSettingsEGameOrientation;
    /**
     * 
     * @type {FxIdDomainSettingsEGameOrientation}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    MobileGameOrientation: FxIdDomainSettingsEGameOrientation;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponsePresentation}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    Presentation?: FxIdWebFeaturesPlayGameResponsePresentation | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponsePresentation}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    MobilePresentation?: FxIdWebFeaturesPlayGameResponsePresentation | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponsePresentation}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    ViewportPresentation?: FxIdWebFeaturesPlayGameResponsePresentation | null;
    /**
     * 
     * @type {Array<string>}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    SupportedLanguages: Array<string>;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponseSupport}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    Support?: FxIdWebFeaturesPlayGameResponseSupport | null;
    /**
     * 
     * @type {FxIdWebFeaturesPlayPublicDataBaseStoreOptions}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    Store: FxIdWebFeaturesPlayPublicDataBaseStoreOptions;
    /**
     * 
     * @type {FxIdWebFeaturesPlayGameResponseFxLauncherOptions}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    FxLauncherOptions?: FxIdWebFeaturesPlayGameResponseFxLauncherOptions | null;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    CompanyName: string;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    TosUrl: string;
    /**
     * 
     * @type {FxIdDomainSettingsUrlOpenMethod}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    TosUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    PpUrl: string;
    /**
     * 
     * @type {FxIdDomainSettingsUrlOpenMethod}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    PpUrlOpenMethod: FxIdDomainSettingsUrlOpenMethod;
    /**
     * 
     * @type {FxIdDomainSettingsPublicWebClientConfigOptions}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    PublicWebClientConfig: FxIdDomainSettingsPublicWebClientConfigOptions;
    /**
     * 
     * @type {string}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    GameSystemName: string;
    /**
     * 
     * @type {boolean}
     * @memberof FxIdWebFeaturesPlayPublicDataBase
     */
    NamespacedProductsL10N: boolean;
}



/**
 * Check if a given object implements the FxIdWebFeaturesPlayPublicDataBase interface.
 */
export function instanceOfFxIdWebFeaturesPlayPublicDataBase(value: object): value is FxIdWebFeaturesPlayPublicDataBase {
    if (!('PlatformData' in value) || value['PlatformData'] === undefined) return false;
    if (!('GameOrientation' in value) || value['GameOrientation'] === undefined) return false;
    if (!('MobileGameOrientation' in value) || value['MobileGameOrientation'] === undefined) return false;
    if (!('SupportedLanguages' in value) || value['SupportedLanguages'] === undefined) return false;
    if (!('Store' in value) || value['Store'] === undefined) return false;
    if (!('CompanyName' in value) || value['CompanyName'] === undefined) return false;
    if (!('TosUrl' in value) || value['TosUrl'] === undefined) return false;
    if (!('TosUrlOpenMethod' in value) || value['TosUrlOpenMethod'] === undefined) return false;
    if (!('PpUrl' in value) || value['PpUrl'] === undefined) return false;
    if (!('PpUrlOpenMethod' in value) || value['PpUrlOpenMethod'] === undefined) return false;
    if (!('PublicWebClientConfig' in value) || value['PublicWebClientConfig'] === undefined) return false;
    if (!('GameSystemName' in value) || value['GameSystemName'] === undefined) return false;
    if (!('NamespacedProductsL10N' in value) || value['NamespacedProductsL10N'] === undefined) return false;
    return true;
}

export function FxIdWebFeaturesPlayPublicDataBaseFromJSON(json: any): FxIdWebFeaturesPlayPublicDataBase {
    return FxIdWebFeaturesPlayPublicDataBaseFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayPublicDataBase {
    if (json == null) {
        return json;
    }
    return {
        
        'PlatformData': FxIdWebFeaturesPlayPlatformDataOptionsFromJSON(json['PlatformData']),
        'Statistics': json['Statistics'] == null ? undefined : FxIdWebFeaturesPlayGameResponseStatisticsFromJSON(json['Statistics']),
        'GameOrientation': FxIdDomainSettingsEGameOrientationFromJSON(json['GameOrientation']),
        'MobileGameOrientation': FxIdDomainSettingsEGameOrientationFromJSON(json['MobileGameOrientation']),
        'Presentation': json['Presentation'] == null ? undefined : FxIdWebFeaturesPlayGameResponsePresentationFromJSON(json['Presentation']),
        'MobilePresentation': json['MobilePresentation'] == null ? undefined : FxIdWebFeaturesPlayGameResponsePresentationFromJSON(json['MobilePresentation']),
        'ViewportPresentation': json['ViewportPresentation'] == null ? undefined : FxIdWebFeaturesPlayGameResponsePresentationFromJSON(json['ViewportPresentation']),
        'SupportedLanguages': json['SupportedLanguages'],
        'Support': json['Support'] == null ? undefined : FxIdWebFeaturesPlayGameResponseSupportFromJSON(json['Support']),
        'Store': FxIdWebFeaturesPlayPublicDataBaseStoreOptionsFromJSON(json['Store']),
        'FxLauncherOptions': json['FxLauncherOptions'] == null ? undefined : FxIdWebFeaturesPlayGameResponseFxLauncherOptionsFromJSON(json['FxLauncherOptions']),
        'CompanyName': json['CompanyName'],
        'TosUrl': json['TosUrl'],
        'TosUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodFromJSON(json['TosUrlOpenMethod']),
        'PpUrl': json['PpUrl'],
        'PpUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodFromJSON(json['PpUrlOpenMethod']),
        'PublicWebClientConfig': FxIdDomainSettingsPublicWebClientConfigOptionsFromJSON(json['PublicWebClientConfig']),
        'GameSystemName': json['GameSystemName'],
        'NamespacedProductsL10N': json['NamespacedProductsL10N'],
    };
}

export function FxIdWebFeaturesPlayPublicDataBaseToJSON(json: any): FxIdWebFeaturesPlayPublicDataBase {
    return FxIdWebFeaturesPlayPublicDataBaseToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayPublicDataBaseToJSONTyped(value?: FxIdWebFeaturesPlayPublicDataBase | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    return {
        
        'PlatformData': FxIdWebFeaturesPlayPlatformDataOptionsToJSON(value['PlatformData']),
        'Statistics': FxIdWebFeaturesPlayGameResponseStatisticsToJSON(value['Statistics']),
        'GameOrientation': FxIdDomainSettingsEGameOrientationToJSON(value['GameOrientation']),
        'MobileGameOrientation': FxIdDomainSettingsEGameOrientationToJSON(value['MobileGameOrientation']),
        'Presentation': FxIdWebFeaturesPlayGameResponsePresentationToJSON(value['Presentation']),
        'MobilePresentation': FxIdWebFeaturesPlayGameResponsePresentationToJSON(value['MobilePresentation']),
        'ViewportPresentation': FxIdWebFeaturesPlayGameResponsePresentationToJSON(value['ViewportPresentation']),
        'SupportedLanguages': value['SupportedLanguages'],
        'Support': FxIdWebFeaturesPlayGameResponseSupportToJSON(value['Support']),
        'Store': FxIdWebFeaturesPlayPublicDataBaseStoreOptionsToJSON(value['Store']),
        'FxLauncherOptions': FxIdWebFeaturesPlayGameResponseFxLauncherOptionsToJSON(value['FxLauncherOptions']),
        'CompanyName': value['CompanyName'],
        'TosUrl': value['TosUrl'],
        'TosUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodToJSON(value['TosUrlOpenMethod']),
        'PpUrl': value['PpUrl'],
        'PpUrlOpenMethod': FxIdDomainSettingsUrlOpenMethodToJSON(value['PpUrlOpenMethod']),
        'PublicWebClientConfig': FxIdDomainSettingsPublicWebClientConfigOptionsToJSON(value['PublicWebClientConfig']),
        'GameSystemName': value['GameSystemName'],
        'NamespacedProductsL10N': value['NamespacedProductsL10N'],
    };
}

