/* tslint:disable */
/* eslint-disable */
/**
 * Web API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: v1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import type { FxIdWebFeaturesPlayFxLauncherOptions } from './FxIdWebFeaturesPlayFxLauncherOptions';
import {
    instanceOfFxIdWebFeaturesPlayFxLauncherOptions,
    FxIdWebFeaturesPlayFxLauncherOptionsFromJSON,
    FxIdWebFeaturesPlayFxLauncherOptionsFromJSONTyped,
    FxIdWebFeaturesPlayFxLauncherOptionsToJSON,
} from './FxIdWebFeaturesPlayFxLauncherOptions';

/**
 * @type FxIdWebFeaturesPlayGameResponseFxLauncherOptions
 * 
 * @export
 */
export type FxIdWebFeaturesPlayGameResponseFxLauncherOptions = FxIdWebFeaturesPlayFxLauncherOptions;

export function FxIdWebFeaturesPlayGameResponseFxLauncherOptionsFromJSON(json: any): FxIdWebFeaturesPlayGameResponseFxLauncherOptions {
    return FxIdWebFeaturesPlayGameResponseFxLauncherOptionsFromJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseFxLauncherOptionsFromJSONTyped(json: any, ignoreDiscriminator: boolean): FxIdWebFeaturesPlayGameResponseFxLauncherOptions {
    if (json == null) {
        return json;
    }
    if (instanceOfFxIdWebFeaturesPlayFxLauncherOptions(json)) {
        return FxIdWebFeaturesPlayFxLauncherOptionsFromJSONTyped(json, true);
    }

    return {} as any;
}

export function FxIdWebFeaturesPlayGameResponseFxLauncherOptionsToJSON(json: any): any {
    return FxIdWebFeaturesPlayGameResponseFxLauncherOptionsToJSONTyped(json, false);
}

export function FxIdWebFeaturesPlayGameResponseFxLauncherOptionsToJSONTyped(value?: FxIdWebFeaturesPlayGameResponseFxLauncherOptions | null, ignoreDiscriminator: boolean = false): any {
    if (value == null) {
        return value;
    }

    if (instanceOfFxIdWebFeaturesPlayFxLauncherOptions(value)) {
        return FxIdWebFeaturesPlayFxLauncherOptionsToJSON(value as FxIdWebFeaturesPlayFxLauncherOptions);
    }

    return {};
}

